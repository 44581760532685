import React from 'react';
import { PageProps } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';
import CommonData from '../../data/common/common';

type Props = PageProps & WithTranslation;

const ElectronicAccessAgreement: React.FC<Props> = (props: Props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
  };

  const values = {
    tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
  };

  const getParagraphsWithValues = (arr: string[]) => {
    return arr.map((item) => {
      // eslint-disable-next-line react/no-danger
      return <p key={`para-${Math.random()}`} dangerouslySetInnerHTML={{ __html: item }} />;
    });
  };

  return (
    <Layout options={layoutOptions} className="ElectronicAccessAgreement">
      <div className="ElectronicAccessAgreement__generic-gap">
        <p>
          <Trans i18nKey="effectiveDate" t={t} />
        </p>
      </div>
      <div className="ElectronicAccessAgreement__generic-gap">
        <h2>{t('partA.heading')}</h2>
        <p>
          <Trans i18nKey="partA.body" t={t} />
        </p>
      </div>
      <div className="ElectronicAccessAgreement__generic-gap">
        <h2>{t('partB.heading')}</h2>
        {getParagraphsWithValues(t('partB.bodyParagraphs', { returnObjects: true, ...values }))}
      </div>
      <div className="ElectronicAccessAgreement__generic-gap">
        <h2>{t('partC.heading')}</h2>
        {getParagraphsWithValues(t('partC.bodyParagraphs', { returnObjects: true }))}
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.ELECTRONIC_ACCESS_AGREEMENT)(
  ElectronicAccessAgreement
);
